import * as React from "react";
import { Helmet } from "react-helmet";
import TopBar from "./TopBar";

const Layout = ({
  children,
}: {
  children: JSX.Element | Array<JSX.Element>;
}) => {
  return (
    <>
      <Helmet
        title="Koncept Bobo"
        bodyAttributes={{
          class: "antialiased bg-body text-body font-body",
        }}
        defer={false}
      />
      <TopBar />
      {children}
    </>
  );
};

export default Layout;
