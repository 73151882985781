import * as React from "react";
import Layout from "../components/Layout";
import Intro from "../sections/Intro";
import Contact from "../sections/Contact";
import ExpandedContact from "../sections/ExpandedContact";
import Footer from "../sections/Footer";
import MobileApp from "../sections/MobileApp";

const IndexPage = () => {
  return (
    <Layout>
      <Intro />
      <Contact />
      <MobileApp />
      <ExpandedContact />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
