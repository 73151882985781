import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const MobileApp = () => (
  <section id="mobile-app" className="py-12 md:py-20">
    <div className="container px-4 mx-auto">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/3 px-4 mt-12 md:mt-0 order-last md:order-first">
          <div className="flex flex-row order-last md:order-first justify-center md:justify-start">
            <div className="relative">
              <div
                className="absolute bg-blue-600"
                style={{ top: "3%", left: "7%", width: "87%", height: "94%" }}
              >
                <StaticImage
                  quality={100}
                  src="../images/Screenshot-1620994260.png"
                  alt="Asystent Ciąży"
                  className="object-cover w-full h-full"
                />
              </div>
              <StaticImage
                quality={100}
                src="../images/iphone.png"
                alt=""
                className="relative mx-auto"
              />
            </div>
          </div>
        </div>
        <div className="w-full md:w-2/3 lg:max-w-lg px-4">
          <div className="flex h-full">
            <div className="my-auto">
              <div className="mb-8">
                <h2 className="mb-6 text-3xl md:text-4xl font-bold font-heading">
                  Uwielbiana przez Mamy Aplikacja Ciążowa
                </h2>
                <p className="leading-loose text-blueGray-400">
                  Ocena 4.9/5 (Google Play) nie bierze się z nikąd. Asystent
                  Ciąży to jedna z najlepszych polskich aplikacji ciążowych na
                  rynku.{" "}
                </p>
              </div>
              <div className="flex items-center">
                <div className="relative w-2/5">
                  <StaticImage
                    quality={100}
                    src="../images/googleplay.png"
                    alt="Asystent Ciąży - ocena w google play"
                    className="rounded-lg"
                  />
                </div>
                <div className="ml-8 w-3/5">
                  <p className="text-blueGray-400 leading-relaxed">
                    Świetne oceny aplikacji odzwierciedlają zaufanie którymi
                    obdarzyły nas Mamy!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default MobileApp;
