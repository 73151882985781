import  React, {useState} from "react";
import { StaticImage } from "gatsby-plugin-image";

const TopBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <section>
      <div className="container px-4 mx-auto">
        <nav className="flex items-center py-6">
          <a className="text-3xl font-semibold leading-none" href="/">
            <StaticImage
              quality={100}
              placeholder="none"
              src="../images/1cover.png"
              alt="Koncept Bobo"
              width={200}
            />
          </a>
          <div className="lg:hidden ml-auto">
            <button onClick={() => setMenuOpen(true)} className="navbar-burger flex items-center py-2 px-3 text-blue-600 hover:text-blue-700 rounded border border-blue-200 hover:border-blue-300">
              <svg
                className="fill-current h-4 w-4"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu mobilne</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <ul className="hidden lg:flex ml-12 mr-auto space-x-12">
            <li>
              <a
                className="text-sm text-blueGray-400 hover:text-blueGray-500"
                href="/#intro"
              >
                Dotrzyj do swoich klientek
              </a>
            </li>
            <li>
              <a
                className="text-sm text-blueGray-400 hover:text-blueGray-500"
                href="/#contact"
              >
                Kontakt
              </a>
            </li>
            <li>
              <a
                className="text-sm text-blueGray-400 hover:text-blueGray-500"
                href="/#mobile-app"
              >
                Asystent Ciąży
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className={`navbar-menu relative z-50 ${menuOpen ? "" : "hidden"}`}>
        <div onClick={() => setMenuOpen(false)} className="navbar-backdrop  fixed inset-0 bg-blueGray-800 opacity-25" />
        <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
          <div className="flex items-center mb-8">
            <a className="mr-auto text-3xl font-semibold leading-none" href="/">
              <StaticImage
                quality={100}
                placeholder="none"
                src="../images/1cover.png"
                alt="Koncept Bobo"
                width={200}
              />
            </a>
            <button className="navbar-close" onClick={() => setMenuOpen(false)}>
              <svg
                className="h-6 w-6 text-blueGray-400 cursor-pointer hover:text-blueGray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div>
            <ul>
              <li className="mb-1">
                <a onClick={() => setMenuOpen(false)}
                  className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-600"
                  href="/#intro"
                >
                  Dotrzyj do swoich klientek
                </a>
              </li>
              <li className="mb-1">
                <a onClick={() => setMenuOpen(false)}
                  className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-600"
                  href="/#contact"
                >
                  Kontakt
                </a>
              </li>
              <li className="mb-1">
                <a onClick={() => setMenuOpen(false)}
                  className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-600"
                  href="/#mobile-app"
                >
                  Asystent Ciąży
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default TopBar;
