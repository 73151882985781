import * as React from "react";

const Intro = () => (
  <section id="intro" className="pt-16 pb-20 bg-blueGray-50">
    <div className="container px-4 mx-auto">
      <div className="flex flex-wrap items-center mb-12">
        <div className="w-full lg:w-1/2 mb-6 lg:mb-0">
          <h2 className="text-4xl font-bold font-heading">
            <span>Zaprezentuj swoje </span>
            <span className="text-blue-600">produkty i usługi</span>
            <span> przyszłym i młodym rodzicom.</span>
          </h2>
        </div>
        <div className="w-full lg:w-1/2">
          <p className="lg:pl-16 text-blueGray-400 leading-loose">
            Dotrzyj ze swoim przekazem do kobiet w ciąży, lub młodych Mam.
            Oferujemy niedostępne nigdzie indziej precyzyjne targetowanie nawet
            na poziomie aktualnego tygodnia ciąży. Mobile, email, remarketing.
          </p>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 -mb-6">
        <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
          <div className="pt-8 px-6 pb-6 bg-white text-center rounded shadow">
            <svg
              className="w-10 h-10 mx-auto mb-4 text-blue-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
              />
            </svg>
            <h3 className="mb-2 font-bold font-heading">
              Wiele Kanałów Komunikacji
            </h3>
            <p className="text-sm text-blueGray-400">
              Mobile, email, remarketing. Twoje produkty wszędzie tam gdzie są
              idealni klienci.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
          <div className="pt-8 px-6 pb-6 bg-white text-center rounded shadow">
            <svg
              className="w-10 h-10 mx-auto mb-4 text-blue-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
              />
            </svg>
            <h3 className="mb-2 font-bold font-heading">
              Precyzyjnie dobrani odbiorcy
            </h3>
            <p className="text-sm text-blueGray-400">
              Niedostępne nigdzie indziej precyzyjne targetowanie. Wybierz
              trymestr lub nawet tydzień ciąży.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
          <div className="pt-8 px-6 pb-6 bg-white text-center rounded shadow">
            <svg
              className="w-10 h-10 mx-auto mb-4 text-blue-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            <h3 className="mb-2 font-bold font-heading">
              Rozliczaj się za efekt
            </h3>
            <p className="text-sm text-blueGray-400">
              CPM lub CPC - wybierz najbardziej odpowiednią dla Ciebie formę
              rozliczeń.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
          <div className="pt-8 px-6 pb-6 bg-white text-center rounded shadow">
            <svg
              className="w-10 h-10 mx-auto mb-4 text-blue-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
              />
            </svg>
            <h3 className="mb-2 font-bold font-heading">
              Solidny i sprawdzony partner
            </h3>
            <p className="text-sm text-blueGray-400">
              Towarzyszymy przyszłym rodzicom już od 2018 roku. Skorzystaj z
              naszego doświaczenia.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Intro;
