import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => (
  <section className="py-16">
    <div className="container px-4 mx-auto">
      <div className="flex flex-col lg:flex-row flex-wrap items-center justify-center lg:justify-between">
        <div className="flex flex-col lg:flex-row mb-8 lg:mb-0">
          <a
            className="inline-block mb-8 lg:mb-0 mx-auto lg:ml-0 lg:mr-12 text-3xl font-semibold leading-none"
            href="/"
          >
            <StaticImage
              quality={90}
              placeholder="none"
              src="../images/1cover.png"
              alt="Koncept Bobo"
              width={200}
            />
          </a>
        </div>
        <div className="lg:flex lg:flex-wrap lg:items-center">
          <a
            className="inline-block py-4 px-8 text-xs text-white leading-none bg-blue-600 hover:bg-blue-700 font-semibold rounded break-words"
            href="mailto:karen.babajan@konceptbobo.pl"
          >
            Proszę o wycenę
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default Footer;
