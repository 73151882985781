import * as React from "react";

const Contact = () => (
  <section className="py-16">
    <div className="container px-4 mx-auto">
      <div className="max-w-lg mx-auto text-center">
        <span className="inline-block py-1 px-3 text-xs md:text-sm rounded-xl bg-blue-50 text-blue-600 font-semibold">
          Współpraca
        </span>
        <h2 className="my-3 text-3xl md:text-4xl font-bold font-heading">
          Wybierzemy najlepsze kanały komunikacji
        </h2>
        <p className="text-blueGray-400 leading-relaxed">
          Dotrzyj ze swoją ofertą dokładnie tam gdzie swój czas spędzają Twoi
          klienci.
        </p>
        <div className="mt-8">
          <a
            className="block sm:inline-block py-4 px-8 mb-4 sm:mb-0 sm:mr-3 text-sm text-white text-center font-semibold leading-none bg-blue-600 hover:bg-blue-700 rounded"
            href="/#contact"
          >
            Porozmawiajmy
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default Contact;
